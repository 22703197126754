import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import BasicParagraph from '../styled/Paragraph'
import { useCookies } from 'react-cookie'

const Wrapper = styled(BasicParagraph)`
  position: fixed;
  transform: translateZ(0);
  left: 14px;
  bottom: ${({highCookies }) => highCookies ? '88px' : '14px'};
  max-width: 187px;
  padding: 0 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.22);
  line-height: 1;
  z-index: 3;
  background-color: ${({ theme }) => theme.white};
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  font-family: ${({ theme }) => theme.fontSansMedium};

  @media only screen and (min-width: 1024px) {
    left: 10px;
    bottom: 6px;
  }
`

const Content = styled.div`
  padding: 8px 0 11px;
`
const AcceptButton = styled(BasicParagraph)`
  font-family: ${({ theme }) => theme.fontSansMedium};

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`
const Link = styled.a`
  text-decoration: underline;
`

const CookieNotice = ({highCookies}) => {
  const [cookies, setCookie] = useCookies(['d-cn'])
  const [show, setShow] = useState(false)

  const handleButtonClick = () => {
    setShow(false)
    setCookie('d-cn', 1, { path: '/' })
  }

  useEffect(() => {
    if (cookies['d-cn']) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [])
  return (
    <Wrapper show={show} as="div" size="xs" sans highCookies={highCookies}>
      <Content>
        We use cookies you get the best experience on our website. By continuing
        you agree to our <Link href="/privacy-policy">Cookies Policy</Link>.
      </Content>
      <AcceptButton
        as="button"
        size="xs"
        sans
        uppercase
        onClick={handleButtonClick}
      >
        Accept
      </AcceptButton>
    </Wrapper>
  )
}

export default CookieNotice
