import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { animated } from 'react-spring'

const Wrapper = styled(animated.div)`
  padding: 0 35px;
  font-size: 24px;
  letter-spacing: 2.15px;
  text-transform: uppercase;
  padding-bottom: 20px;
  position: relative;
  display: inline-block;

  @media only screen and (min-width: 1024px) {
    padding-left: 40px;
    font-size: 28px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 15px;
    top: 10px;
    height: 1px;
    width: 10px;
    background-color: ${({ theme }) => theme.black};
    opacity: ${({ active }) => (active ? 1 : 0)};
    transition: opacity 0.15s ease-in-out;

    @media only screen and (min-width: 1024px) {
      top: 10px;
    }
  }
`

const MenuItem = ({
  to,
  children,
  location,
  springProps,
  hoveredItem,
  setHoveredItem,
  index,
  closeMenu,
}) => {
  const isActive = () => {
    if (hoveredItem === false) {
      if (location.pathname === '/') {
        return location.pathname === to
      } else {
        return to !== '/' && location.pathname.startsWith(to)
      }
    } else {
      return hoveredItem === index
    }
  }

  return (
    <Wrapper
      active={isActive()}
      style={{ ...springProps }}
      onMouseEnter={() => setHoveredItem(index)}
      onMouseLeave={() => setHoveredItem(false)}
      onClick={closeMenu}
    >
      <Link to={to}>{children}</Link>
    </Wrapper>
  )
}

export default MenuItem
