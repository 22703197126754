/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { GlobalStyle } from './GlobalStyle'
import { ThemeProvider } from 'styled-components'
import theme from '../helpers/theme'
import { ContextProviderComponent } from '../helpers/context'
import { CookiesProvider } from 'react-cookie'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'

import '../fonts/fonts.css'

import Header from '../components/layout/Header'
import PageTitle from '../components/layout/PageTitle'
import Main from '../components/layout/Main'
import Footer from '../components/layout/Footer'

const Layout = ({ children, location, ...props }) => {
  const [hideMain, setHideMain] = useState(false)

  const isHome = location && location.pathname === '/'
  const isDesigners =
    location &&
    (location.pathname === '/designers' || location.pathname === '/designers/')
  const isExhibition =
    location &&
    (location.pathname === '/exhibition' ||
      location.pathname === '/exhibition/')
  const isMedia =
    location &&
    (location.pathname === '/media' || location.pathname === '/media/')
  const isProject = props.pageContext && props.pageContext.isProject

  const isTextPage = props.pageContext && props.pageContext.isTextPage

  const is404 =
    props.pageResources && props.pageResources.page.path === '/404.html'

  const isAbout =
    location &&
    (location.pathname === '/about' || location.pathname === '/about/')

  const getCurrent = () => {
    if (isHome || is404 || isTextPage) {
      return 'More'
    } else if (isProject) {
      return 'Designers'
    } else {
      if (location && location.pathname) {
        const pathname = location.pathname
        const last = location.pathname.slice(-1)

        if (last === '/') {
          return pathname.substring(1, pathname.length - 1)
        } else {
          return pathname.substring(1)
        }
      }
    }
  }

  useEffect(() => {
    if (location.action === 'PUSH') {
      setTimeout(() => {
        document.body.scroll(0, 0)
      }, 150);
    }
  }, [location])

  const handleRe = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    window.addEventListener('resize', handleRe, false)
    return () => {
      window.removeEventListener('resize', handleRe)
    }
  }, [])

  return (
    <React.Fragment>
      <GlobalStyle />
      <CookiesProvider>
        <ContextProviderComponent>
          <ThemeProvider theme={theme}>
            <Header
              current={getCurrent()}
              location={location}
              back={isProject}
            />
            <PageTitle
              current={getCurrent()}
              location={location}
              back={isProject}
            />
            <Main short={isExhibition} hide={hideMain} >
              {
                // children
              }
              {
                  typeof window === 'undefined' ? (
                  children
                ) : (
                  <TransitionProvider
                    location={location}
                    leave={{
                      opacity: 0,
                      config: {
                        mass: 1,
                        tension: 500,
                        friction: 35,
                        clamp: true,
                      },
                    }}
                    usual={{
                      opacity: 1,
                    }}
                    enter={{
                      opacity: 0,
                      config: {
                        mass: 1,
                        tension: 500,
                        friction: 35,
                        clamp: true,
                      },
                    }}
                  >
                    <TransitionViews>{children}</TransitionViews>
                  </TransitionProvider>
                )
              }
            </Main>
            {!is404 && (
              <Footer
                fixed={isHome}
                highCookies={isHome}
                location={location}
                extended={isHome}
                noBorder={isProject || isDesigners || isAbout}
                hide={!isDesigners && !isExhibition && !isMedia && !isTextPage}
              />
            )}
          </ThemeProvider>
        </ContextProviderComponent>
      </CookiesProvider>
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
