import React from 'react'
import styled from 'styled-components'
import ContextConsumer from '../../helpers/context'

import MenuOverlay from './MenuOverlay'

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
`

const OverlayBg = styled.div`
  display: none;

  @media only screen and (min-width: 1024px) {
    display: block;
    position: fixed;
    transform: translateZ(0);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.blackLessOpaque};
    transition: all 0.25s ease-in-out;
    cursor: pointer;
    opacity: ${({ open }) => (open ? 1 : 0)};
    pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
    backdrop-filter: ${({ open }) => (open ? 'blur(2px)' : 'blur(0)')};
  }
`

const Header = ({ current, location, back, ...props }) => {
  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Wrapper>
          <OverlayBg
            open={data.menuOpen}
            onClick={() => set({ menuOpen: false })}
          />
          <MenuOverlay context={data} setContext={set} location={location} />
        </Wrapper>
      )}
    </ContextConsumer>
  )
}

export default Header
