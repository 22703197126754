import styled from 'styled-components'

const Main = styled.main`
  position: relative;
  z-index: 1;
  min-height: ${({ short }) => (short ? '80vh' : '100vh')};

  @media only screen and (min-width: 1024px) {
    opacity: ${({ hide }) => (hide ? 0 : 1)};
    min-height: 100vh;
  }
`

export default Main
