import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'

import ContextConsumer from '../../helpers/context'

import PlusSvg from '../../assets/plus.svg'
import ArrowSvg from '../../assets/arrow-left.svg'

import W from '../styled/Wrapper'

const move = keyframes`
  0% {
    transform: translateX(0px);
    opacity: 1;
  }

	17% {
		opacity:0.5;
	}
	34%{
    transform: translateX(-10px);
	  opacity: 0;
		
	}
	50% {
    transform: translateX(10px);
	  opacity: 0;
  }
  67% {
    opacity: 0.5;
  }
  100% {
    transform: translateX(0px);
	 opacity: 1;
  }
`

const Wrapper = styled.div`
  /* position: ${({ fix }) => (fix ? 'fixed' : 'absolute')}; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  pointer-events: ${({ hide }) => (hide ? 'none' : 'auto')};
`

const Text = styled.button`
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1.94px;
  text-transform: uppercase;
  padding: 30px 0 0;
  display: inline-block;
  color: ${({ theme, white }) => (white ? theme.white : theme.black)};
  /* transition: color 0.15s ease-in-out; */

  @media only screen and (min-width: 1024px) {
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 2px;
  }

`

const ArrowIcon = styled(ArrowSvg)`
  height: 11px;
  width: 11px;
  position: relative;
  top: -3px;
  transition: transform 0.15s ease-out;

  g.fill-el,
  rect.fill-el {
    /* transition: fill 0.15s ease-in-out; */
    fill: ${({ theme, white }) => (white ? theme.white : theme.black)};
  }

  path.stroke-el {
    /* transition: stroke 0.15s ease-in-out; */
    stroke: ${({ theme, white }) => (white ? theme.white : theme.black)};
  }

  @media (hover: hover) {
    ${Text}:hover & {
      animation: ${move} 0.6s ease-in-out;
    }
  }
`

const PlusIcon = styled(PlusSvg)`
  height: 11px;
  width: 11px;
  position: relative;
  top: -3px;
  transition: transform 0.15s ease-out;

  g.fill-el,
  rect.fill-el {
    fill: ${({ theme, white }) => (white ? theme.white : theme.black)};
  }

  path.stroke-el {
    stroke: ${({ theme, white }) => (white ? theme.white : theme.black)};
  }

  @media (hover: hover) {
    ${Text}:hover & {
      transform: rotate(90deg);
    }
  }
`

const PageTitle = ({ back, current }) => {
  const [fix, setFix] = useState(false)
  const [white, setWhite] = useState(false)

  const isDt = useMediaQuery({
    query: '(min-width: 1024px)',
  })

  const handleScroll = e => {
    setFix(e.target.scrollTop > 0)

    if (
      document.getElementById('d-01')?.getBoundingClientRect()?.top <
        window.innerHeight / 20 &&
      document.getElementById('l-01')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('d-02')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-02')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('d-03')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-03')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('d-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10
    ) {
      setWhite(true)
    } else if (
      document.getElementById('l-01')?.getBoundingClientRect()?.top <
        window.innerHeight / 20 &&
      document.getElementById('d-02')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-02')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('d-03')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-03')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('d-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10
    ) {
      setWhite(false)
    } else if (
      document.getElementById('d-02')?.getBoundingClientRect()?.top <
        window.innerHeight / 20 &&
      document.getElementById('l-02')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('d-03')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-03')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('d-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10
    ) {
      setWhite(true)
    } else if (
      document.getElementById('l-02')?.getBoundingClientRect()?.top <
        window.innerHeight / 20 &&
      document.getElementById('d-03')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-03')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('d-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10
    ) {
      setWhite(false)
    } else if (
      document.getElementById('d-03')?.getBoundingClientRect()?.top <
        window.innerHeight / 20 &&
      document.getElementById('l-03')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('d-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10
    ) {
      setWhite(true)
    } else if (
      document.getElementById('l-03')?.getBoundingClientRect()?.top <
        window.innerHeight / 20 &&
      document.getElementById('d-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10 &&
      document.getElementById('l-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10
    ) {
      setWhite(false)
    } else if (
      document.getElementById('d-04')?.getBoundingClientRect()?.top <
        window.innerHeight / 20 &&
      document.getElementById('l-04')?.getBoundingClientRect()?.top >
        window.innerHeight / 10
    ) {
      setWhite(true)
    } else if (
      document.getElementById('l-04')?.getBoundingClientRect()?.top <
      window.innerHeight / 20
    ) {
      setWhite(false)
    } else {
      setWhite(false)
    }
  }

  useEffect(() => {
    if ((current !== 'materials' || isDt) && current !== 'designers') {
      document.body.addEventListener('scroll', handleScroll)
    }
    return () => {
      if ((current !== 'materials' || isDt) && current !== 'designers') {
        document.body.removeEventListener('scroll', handleScroll)
      }
    }
  })

  useEffect(() => {
    if (current === 'exhibition') {
      setWhite(true)
    } else {
      setWhite(false)
    }
    setFix(window.scrollY > 0)
  }, [current])

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Wrapper hide={data.videoOpen} fix={fix}>
          <W>
            {back ? (
              <Text as={Link} to={'/designers'} white={white}>
                <ArrowIcon white={white} /> {current}
              </Text>
            ) : (
              <Text onClick={() => set({ menuOpen: true })} white={white}>
                {current} <PlusIcon white={white} />
              </Text>
            )}
          </W>
        </Wrapper>
      )}
    </ContextConsumer>
  )
}

export default PageTitle
