import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import BasicParagraph from '../styled/Paragraph'
import CookieNotice from './CookieNotice'
import { Link as L } from 'gatsby'

import W from '../styled/Wrapper'

const Container = styled(W)`
  position: relative;
  background-color: ${({ theme }) => theme.white};
  pointer-events: none;

  @media only screen and (min-width: 1024px) {
    position: ${({ fixed }) => (fixed ? 'fixed' : 'relative')};
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${({ fixed }) => (fixed ? '0' : '1')};
    opacity: ${({ hideFooter }) => (hideFooter ? 0 : 1)};
    pointer-events: ${({ hideFooter }) => (hideFooter ? 'none' : 'none')};
  }
`

const Spacer = styled.div`
  @media only screen and (min-width: 1024px) {
    height: ${({ fixed }) => (fixed ? '450px' : '0')};
    pointer-events: auto;
  }
`

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.black};
  width: 100%;
  margin: 0;
  bottom: 0;
  left: 0;
  padding: 55px 0 24px;
  pointer-events: none;

  @media only screen and (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 120px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 71px 0 30px;
    flex-direction: row;
    justify-content: space-between;
  }
`

const Top = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: ${({ theme }) => theme.black};
  width: 100%;
  position: relative;
  margin-bottom: 45px;

  @media only screen and (max-width: 445px) {
    grid-template-columns: 1fr 205px;
  }

  @media only screen and (min-width: 768px) {
    margin-bottom: 75px;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 60%;
  }
`

const LogoGrid = styled.div`
  display: flex;
  flex-direction: column;
`

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    pointer-events: auto;
  }

  @media only screen and (min-width: 768px) {
    align-items: flex-end;
    flex-direction: row;
    height: 100%;
  }
`

const LogoWrapperHeader = styled(BasicParagraph)`
  margin-bottom: 29px;
  pointer-events: auto;

  @media only screen and (min-width: 1024px) {
    margin-bottom: 10px;
  }
`

const LogoAhec = styled.img`
  margin-bottom: 28px;
  height: 99px;
  pointer-events: auto;

  @media only screen and (min-width: 768px) {
    margin-right: 49px;
    margin-bottom: 0;
  }

  @media only screen and (min-width: 1024px) {
    margin-right: 37px;
  }
`
const LogoW = styled.img`
  height: 33px;
  pointer-events: auto;
`

const LogoDm = styled.img`
  height: 55px;
  pointer-events: auto;
`

const Bottom = styled(BasicParagraph)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 30px;
  grid-template-areas:
    'a b'
    'c c';
  font-family: ${({ theme }) => theme.fontSansMedium};

  @media only screen and (min-width: 1024px) {
    row-gap: 65px;
  }

  @media only screen and (min-width: 1024px) {
    grid-template-columns: auto;
    grid-template-areas:
      'a b'
      'c d';
    align-items: start;
    row-gap: 0;
    column-gap: 36px;
  }
`

const Links = styled.div`
  @media only screen and (min-width: 1024px) {
    grid-area: c;
  }
`

const Link = styled(L)`
  pointer-events: auto;
`

const Contact = styled.div`
  @media only screen and (min-width: 1024px) {
    grid-area: a;
  }

  a,
  span {
    pointer-events: auto;
  }
`

const Copyrights = styled.div`
  grid-area: c;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'Fas .'
    'Ahec Ok';
  word-wrap: no wrap;

  @media only screen and (max-width: 445px) {
    grid-template-columns: 1fr 205px;
  }

  @media only screen and (min-width: 1024px) {
    display: block;
    grid-area: d;
  }
`

const Discovered = styled.img`
  margin: 0 auto;
  bottom: 0;
  width: 100%;
  height: auto;

  @media only screen and (min-width: 1024px) {
    max-width: 990px;
  }
`

const Fas = styled.a`
  pointer-events: auto;
  grid-area: Fas;
`

const Ahec = styled.p`
  pointer-events: auto;
  grid-area: Ahec;
`

const Ok = styled.p`
  pointer-events: auto;
  grid-area: Ok;
`

const Footer = ({
  location,
  extended,
  noBorder,
  hide,
  fixed,
  highCookies,
  ...props
}) => {
  const [hideFooter, setHideFooter] = useState(true)

  const handleScroll = e => {
    if (hide) {
      setHideFooter(e.target.scrollTop < window.innerHeight)
    } else {
      setHideFooter(false)
    }
  }

  useEffect(() => {
    document.body.addEventListener('scroll', handleScroll)
    return () => {
      document.body.removeEventListener('scroll', handleScroll)
    }
  }, [hide])

  useEffect(() => {
    setHideFooter(hide)
  }, [location])

  return (
    <footer id="footer">
      <CookieNotice highCookies={highCookies} />

      <Spacer fixed={fixed} />

      <Container hideFooter={hideFooter} noBorder={noBorder} fixed={fixed}>
        <Wrapper>
          <Top>
            <LogoGrid>
              <LogoWrapperHeader>Project by:</LogoWrapperHeader>
              <LogoWrapper>
                <a href="https://www.americanhardwood.org/" target="_blank">
                  <LogoAhec src={'/ahec.svg'} />
                </a>
                <a href="https://www.wallpaper.com/" target="_blank">
                  <LogoW src={'/wallpaper.svg'} />
                </a>
              </LogoWrapper>
            </LogoGrid>

            <LogoGrid>
              <LogoWrapperHeader s>In partnership with:</LogoWrapperHeader>

              <LogoWrapper>
                <a href="https://designmuseum.org/" target="_blank">
                  <LogoDm src={'/designmuseum.svg'} />
                </a>
              </LogoWrapper>
            </LogoGrid>
          </Top>

          <Bottom as="div" size="xs" uppercase sans>
            <Links>
              <p>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
            </Links>

            <Contact>
              <p>
                <a href="https://www.instagram.com/explore/tags/discoveredglobal/" target="_blank">#discoveredglobal</a>
              </p>
              <p>
                <a href="mailto:europe@americanhardwood.org" target="_blank">
                  europe@americanhardwood.org
                </a>
              </p>
            </Contact>

            <Copyrights>
              <Fas href="https://www.fas.usda.gov/" target="_blank">
                FAS Online
              </Fas>
              <Ahec> &copy; ahec {new Date().getFullYear()}</Ahec>
              <Ok>
                website:{' '}
                <a href="https://ok-deploy.live/" target="_blank">
                  ok deploy
                </a>
              </Ok>
            </Copyrights>
          </Bottom>
        </Wrapper>

        <Discovered as="img" src={'/discovered.svg'} />
      </Container>
    </footer>
  )
}

export default Footer
