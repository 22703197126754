export default {
  white: '#ffffff',
  black: '#000000',
  fontSerif: 'portraitregular, serif',
  fontSans: 'graphikregular, sans-serif',
  fontSansMedium: 'graphikmedium, sans-serif',
  blackOpaque: 'rgba(0, 0, 0, 0.4)',
  whiteOpaque: 'rgba(255, 255, 255, 0.4)',
  blackLessOpaque: 'rgba(0, 0, 0, 0.6)',
  blackEvenLessOpaque: 'rgba(0, 0, 0, 0.3)',
  maxWidth: '1360px',
  maxWidthSmall: '1290px'
};