import styled from 'styled-components'

const Paragraph = styled.p`
  font-size: ${({ size }) => {
    switch (size) {
      case 'l':
        return '26px'
      case 'm':
        return '20px'
      case 's':
        return '18px'
      case 'xs':
        return '11px'
      default:
        return '18px'
    }
  }};

  letter-spacing: 0;

  line-height: ${({ size }) => {
    switch (size) {
      case 'l':
        return '30px'
      case 'm':
        return '25px'
      case 's':
        return '26px'
      case 'xs':
        return '26px'
      default:
        return '26px'
    }
  }};

@media only screen and (min-width: 768px) {
    font-size: ${({ size }) => {
      switch (size) {
        case 'l':
          return '32px'
        case 'm':
          return '22px'
        case 's':
          return '20px'
        case 'xs':
          return '12px'
        default:
          return '20px'
      }
    }};

    letter-spacing: 0;

    line-height: ${({ size }) => {
      switch (size) {
        case 'l':
          return '42px'
        case 'm':
          return '27px'
        case 's':
          return '25px'
        case 'xs':
          return '22px'
        default:
          return '25px'
      }
    }};
  }

  @media only screen and (min-width: 1100px) {
    font-size: ${({ size }) => {
      switch (size) {
        case 'l':
          return '38px'
        case 'm':
          return '25px'
        case 's':
          return '18px'
        case 'xs':
          return '12px'
        default:
          return '20px'
      }
    }};

    letter-spacing: 0;

    line-height: ${({ size }) => {
      switch (size) {
        case 'l':
          return '48px'
        case 'm':
          return '32px'
        case 's':
          return '26px'
        case 'xs':
          return '22px'
        default:
          return '25px'
      }
    }};
  }

  font-family: ${({ sans, theme, bold }) =>
    !sans ? theme.fontSerif : bold ? theme.fontSansMedium : theme.fontSans};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'auto')};
`

export default Paragraph
