import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import LogoSvg from '../../assets/discovered.svg'
import CloseSvg from '../../assets/plus.svg'

import { useMediaQuery } from 'react-responsive'

import { useSpring, useTrail, animated } from 'react-spring'

import MenuItem from './MenuItem'

const navItems = [
  {
    to: '/',
    label: 'Home',
  },
  {
    to: '/about',
    label: 'About',
  },
  {
    to: '/designers',
    label: 'Designers',
  },
  {
    to: '/materials',
    label: 'Materials',
  },
  {
    to: '/exhibition',
    label: 'Exhibition',
  },
  {
    to: '/media',
    label: 'Media',
  },
]

const Wrapper = styled(animated.div)`
  position: fixed;
  transform: translateZ(0);
  top: 0;
  left: -100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 1024px) {
    width: 33vw;
    min-width: 350px;
    left: -40vw;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 60px;
  width: 36px;
  padding: 30px 14px;

  @media only screen and (min-width: 1024px) {
    left: auto;
    right: 0;
    width: 50px;
    padding: 30px 16px;
  }
`

const CloseIcon = styled(CloseSvg)`
  height: 13px;
  width: 13px;
  transform: rotate(45deg);
  transition: transform 0.15s ease-out;

  @media (hover: hover) {
    ${CloseButton}:hover & {
      transform: rotate(135deg);
    }
  }
`

const Items = styled.div`
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (min-width: 1024px) {
    padding-top: 30px;
  }
`

const Logo = styled(LogoSvg)`
  width: 90%;
  margin-left: 5%;

  @media only screen and (min-width: 1024px) {
    width: calc(100% - 80px);
    margin-left: 40px;
  }
`

const MenuOverlay = ({ location, setContext, context }) => {
  const [hoveredItem, setHoveredItem] = useState(false)

  const isDt = useMediaQuery({
    query: '(min-width: 1024px)',
  })

  const wrapperSpring = useSpring({
    delay: context.menuOpen ? 0 : 100,
    transform: context.menuOpen
      ? isDt
        ? 'translateX(40vw)'
        : 'translateX(100vw)'
      : 'translateX(0vw)',
  })

  const itemsTrail = useTrail(navItems.length, {
    transform: context.menuOpen ? 'translateX(0)' : 'translateX(-50px)',
    config: { mass: 3, tension: 2500, friction: 200 },
  })

  const closeMenu = () => {
    setContext({ menuOpen: false })
  }

  useEffect(() => {
    closeMenu()
  }, [location.pathname])

  return (
    <Wrapper style={{ ...wrapperSpring }}>
      <CloseButton onClick={closeMenu}>
        <CloseIcon />
      </CloseButton>

      <Items>
        {itemsTrail.map((item, index) => {
          return (
            <MenuItem
              springProps={context.menuOpen ? item : itemsTrail[itemsTrail.length - index]}
              // springProps={item}
              to={navItems[index].to}
              key={index}
              location={location}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              index={index}
              closeMenu={closeMenu}
            >
              {navItems[index].label}
            </MenuItem>
          )
        })}
      </Items>

      <Logo />
    </Wrapper>
  )
}

export default MenuOverlay
