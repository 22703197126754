import styled from 'styled-components'

const Wrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: ${({theme, borderB}) => borderB ? `1px solid ${theme.black}` : 0};

  @media only screen and (min-width: 768px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  
  @media only screen and (min-width: 1024px) {
    padding-right: 40px;
    padding-left: 40px;
  }
`

export default Wrapper
